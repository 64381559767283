@import "normalize";
@import "variables";

html { font-size: $basefont-size;
  @include fluid-type(font-size, 768px, 1190px, 14px, 16px);

}

body {padding: 0; margin: 0; line-height: $base-lineheight; font-family: $basefont1, $basefont2, $basefont3;color: $basefont-color;font-size: 1.125rem;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
*{box-sizing: border-box;}

h1, h2, h3, p {margin: 0;}
h1 {font-size: 4rem;margin-bottom: 1.5rem;font-weight: 400;line-height: 5rem;}
h2 {font-size: 2.8125rem;margin-bottom: 1.25rem;font-weight: 500;}
h3 {font-size: 2.25rem;margin-bottom: 1.25rem;font-weight: 500;}
h4 {font-size: 1.5rem;margin-bottom: 1rem;font-weight: 700;line-height: 1.875rem;}
h5 {font-size: 1.25rem;margin-bottom: 1rem;font-weight: 900;line-height: 1.5625rem;}
p {font-size: 1.125rem;margin-bottom: 1rem;font-weight: 400;line-height: 1.375rem;}
a {color: $basefont-color;
  &:hover {color: $basefont-color;}
}
img {max-width: 100%;}
.medium-weight {font-weight: 500;}
.font-bold {font-weight: 700;}
.font-extra {font-weight: 800;}
.font-black {font-weight: 900;}
.errordiv, .errorkont{font-size: .75rem;line-height: 1rem;color: $red;margin-bottom: .625rem;}
input[type="text"].errorredborder, input[type="email"].errorredborder,input[type="password"].errorredborder, textarea.errorredborder, select.errorredborder {border: 1px solid $red !important;}
.error, .error a, .payment-error {color: $red !important;}

.hide {display: none}
.pull-left {float: left}

.mt-6 {margin-top: 4rem;}
.mt-7 {margin-top: 5rem;}

iframe {max-width: 100%;}

::-webkit-input-placeholder {line-height: 1;font-family: $basefont1;font-size: 1rem;color: $basefont-color;}
:-moz-placeholder {font-size: 1rem;font-family: $basefont1;color:$basefont-color;}
::-moz-placeholder {font-size: 1rem;font-family: $basefont1;color:$basefont-color;}
.form-control::placeholder {color:$basefont-color;}

input[type=text]::-ms-clear {
  display: none;
}

textarea:focus,
input:focus{
  outline: 0 none;
  box-shadow:none !important;
}

.main {
  p {
    img {max-width: 100%;height: auto;}
  }
}

.btn {
  &:focus, &:active:focus {box-shadow: none;}
}
.btn-primary { background: transparent; border: 0;border-radius: 0;font-size: 1.5rem;color: $basefont-color;font-weight: 600;padding-right: 0;padding-left: 0;line-height: 1.875rem;
  &:hover, &:active, &:focus, &:disabled { border: 0 !important;color: $basefont-color !important;background: transparent !important;box-shadow: none !important;}
}

.spinner { opacity: 1;position: relative;transition: opacity linear 0.1s;display:none;width:.75rem;height:.75rem;margin-right:0.25rem;
  &::before {animation: 2s linear infinite spinner;border: solid 3px #000;border-bottom-color: #f4e2a3;border-radius: 50%;content: "";height: 0.75rem;width: 0.75rem;left: 50%;opacity: inherit;position: absolute;top: 50%;transform: translate3d(-50%, -50%, 0);transform-origin: center;will-change: transform;}
}

.text-45 {font-size: 2.8125rem !important; margin-bottom: 2rem !important;line-height: 3.625rem;font-weight: 500;}
.text-36 {font-size: 2.25rem !important; margin-bottom: 1.75rem !important;line-height: 2.75rem;font-weight: 500;}
.text-30 {font-size: 1.875rem !important; margin-bottom: 1.25rem !important;line-height: 2.375rem;}
.text-20 {font-size: 1.25rem !important; margin-bottom: 1rem !important;line-height: 1.5rem;}
.text-18 {font-size: 1.125rem !important; margin-bottom: 1rem !important;line-height: 1.375rem;}
.bg-blue {background: $blue !important;}
.bg-yellow {background: $primary-yellow !important;}
.bg-green {background: $green !important;}
.bg-red {background: $red !important;}
.bg-pink {background: $primary-pink !important;}
.bg-light-pink {background: $light-pink !important;}
.color-white {color: #fff !important;
  a {color: #fff;
    &:hover {color: #fff;}
  }
}
.color-blue {color: $blue !important;
  a {color: $blue;
    &:hover {color: $blue;}
  }
}
.color-yellow {color: $primary-yellow !important;
  a {color: $blue;
    &:hover {$primary-yellow: $primary-yellow;}
  }
}
.color-green {color: $green !important;
  a {color: $green;
    &:hover {color: $green;}
  }
}
.color-red {color: $red !important;
  a {color: $red;
    &:hover {color: $red;}
  }
}
.color-pink {color: $primary-pink !important;
  a {color: $primary-pink;
    &:hover {color: $primary-pink;}
  }
}
.color-light-pink {color: $light-pink !important;
  a {color: $light-pink;
    &:hover {color: $light-pink;}
  }
}

nav.navbar {background: #fff;padding-top: .875rem;padding-bottom: .875rem;
  &.scrolled {background: #fff;}
  .navbar-toggler {border:1px solid $primary-pink; box-shadow: none; margin-left: auto;
    .navbar-toggler-icon {background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgb(255, 51, 204)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")}
  }
  .navbar-brand {padding: 0;
    img {height: 2.75rem;width: auto;}
  }
  .nav-link {color: $primary-pink;padding-left: .75rem !important;padding-right: .75rem !important;
    &:hover {text-decoration: underline;}
    &.nav-selected, &.nav-path-selected {font-weight: 700;}
  }
  .navbar-text {
    a {color: $primary-pink;padding-left: .75rem;text-decoration: none;
      &:hover {text-decoration: underline;}
    }
  }
  .dropdown-nav {width: 100%;display: none;color: $basefont-color;
    .close-icon {float:right;color: $basefont-color;font-size: 1.75rem;padding: 0;line-height: 1.75rem;text-decoration:none;
      &:hover, &:active {text-decoration:none;}
    }
  }
  .dropdown-nav-socials { display: none;margin-top: 2rem;position: absolute;bottom: 12rem;width: calc(100% - 3rem);text-align: center;
    a {display: inline-block;height: 24px;width: 24px;margin: 0 .5rem;
      &.behance {background: url("images/socials/icon_be.svg") top center/100% no-repeat;width: 39px;}
      &.insta {background: url("images/socials/icon_insta.svg") top center/100% no-repeat;}
      &.fb {background: url("images/socials/icon_fb.svg") top center/100% no-repeat;}
      &.tiktok {background: url("images/socials/icon_tiktok.svg") top center/100% no-repeat;}
      &.linkedin {background: url("images/socials/icon_linkedin.svg") top center/100% no-repeat;}
      &.vimeo {background: url("images/socials/icon_vimeo.svg") top center/100% no-repeat;}
    }
  }
}
.page-template-main {
  nav.navbar {margin-bottom: 0;background: transparent;position: absolute;width: 100%;top: 0;z-index: 1;
    .nav-link {}
    .navbar-text {
      a {}
    }
  }
}
.page-template-tartalom,
.page-template-blog,
.page-template-szolgaltatas {
  nav.navbar {margin-bottom: 4rem;}
}

.content-block { margin-bottom: 4rem;}
.szolg-content-block {margin-bottom: 4rem;}
.ccm-image-block {margin-bottom: 4rem;}

.tartalmi-block { padding: 5rem 0;
  img.img-fluid {width: 90%}
}

.main-top { height: 100vh;background-position: top center;background-repeat: no-repeat;background-size: cover;position: relative;
  > div {display: flex;height: 100%;}
  .row {width: 100%;}
  p {font-size: 5rem;line-height: 6rem;color: $primary-pink;
    &.extra {font-weight: 800;}
  }
  .szechenyi-fixed {background: url("images/szechenyi_bottom.png") bottom right/100% no-repeat;width: 400px;height: 270px;position: absolute;bottom: 0;right: 0;}
}
.main-top-pink { background: $light-pink;padding: 4rem 0;margin-bottom: 4rem;
  a {font-size: 1.5rem;font-weight: 600;color: $primary-pink;text-decoration: none;}
}

.hello {position: relative;padding: 5rem 0;background-color: $primary-pink;color: $primary-yellow;font-size:1.25rem;font-weight:600;margin-bottom: 4rem;
  p {font-size: 2rem;}
  a {color: $primary-yellow;}
  h1 {display: none;font-size: 9rem;text-transform: uppercase;margin-left: -0.375rem;}

  .wrap {margin: 3.125rem;padding: 3.75rem 0 6.25rem;position: relative;perspective: 3.125rem;text-transform: uppercase;display: block;}
  .text {position: absolute;top: 0;left: 0;color: #ff33cc;font-size: 9.375rem;font-weight: bold;
    text-shadow: -1px -1px 0 #fffa8e, 1px -1px 0 #fffa8e, -1px 1px 0 #fffa8e, 1px 1px 0 #fffa8e;transform-style: preserve-3d;
  }
  text:first-child {position: relative;transform: translate3d(1px, 1px, 5px);}
}

.services { margin-bottom: 4rem;
    .item {display: block;text-decoration: none;margin-bottom: 3rem;
      .arrow {margin-left: .5rem;}
      h3 {font-weight: 900;
        .arrow {margin-left: 1.5rem;}
      }
      > span {font-size: 1.125rem;line-height: 1.375rem;}
    }
  a.item:hover { opacity: 0.8;}
}

.service {
  h3 {font-weight: 900;}
}

.rumour-block { background-color: $light-pink; padding: 2.5rem 0 4rem;margin-bottom: 4rem;
  img {margin-bottom: 1rem;}
  h4 {margin-bottom: .5rem;}
  a {text-decoration: none;}
  .row {padding-top: .75rem;}
  .rumour-left {
    p {font-size: .875rem;line-height: 1.125rem;margin-bottom: 0;}
  }
  .rumour-right {
    a.link-item {display: block;margin-bottom: 2rem;border-left: 2px solid $primary-pink;padding: .5rem .75rem;}
    a.more {display: block;font-size: 1.5rem;font-weight: 600;color: $primary-pink;padding-top: 2rem;}
    h4 {margin-bottom: 0;}
  }
}

.works { margin-bottom: 4rem;
  .masonry {columns: 4 220px;column-gap: 1.875rem;
    .item {margin-bottom: 2rem;display: inline-block;width: 100%;text-decoration: none;
      img {margin-bottom: .875rem;width: 100%;}
      .url {font-size: .875rem;color: $primary-pink;font-weight: 600;display: inline-block;}
      &.regular {
        h3 {font-size: 1.25rem;line-height: 1.5rem;margin-bottom: .75rem;font-weight: 900;}
      }
      p.small-text {font-size: .875rem;line-height: 1.125rem;margin-bottom: 0;
        &.with-url {margin-bottom: 1rem;}
      }
      &.text { padding: 1.875rem;overflow-wrap: break-word;
        h2 {font-weight: 700;margin-bottom: .75rem;}
        h3 {font-weight: 900;}
        &.black-bg {background: #000;color: #fff;}
        &.yellow-bg {background: $primary-yellow;}
        &.light-pink-bg {background: $light-pink;}
        &:not(.subsciption){
          a {font-size: 1.5rem;font-weight: 600;text-decoration: none;  margin-top: .5rem;display: inline-block;}
        }
      }
      &.subsciption {
        h3 {margin-bottom: .625rem;}
        .munkak-feliratkozas-form {margin-top: 1rem;}
        .btn-primary {color: $primary-yellow;padding: 0;margin-top: .5rem;}
        .success_subscribe {color: $primary-pink;margin-top: 1rem;font-size: 1.25rem;}
      }
    }
  }
}

.partners { background: #000;color: #fff;padding: 3rem 0 4rem;
  h2 {margin-bottom: 2rem;}
  .row {justify-content: space-between;}
  .coll {flex: 0 0 auto;width: auto;align-items: flex-end;display: flex;margin: 1rem .9rem;}
  img {}
}

.blog {
  .date {background: $primary-yellow;padding: .25rem;margin-bottom: .75rem;display: inline-block;}
  img {max-width: 100%;height: auto;}
  .blog-content-block {padding-right: 1rem;}
  .blog-bottom {display: flex;justify-content: space-between;width: 100%;padding-right: 1rem;
    .back {margin-top: 1rem;
      a {color: $primary-pink;font-size: 1.5rem;white-space: nowrap;text-decoration: none;font-weight: 600;}
    }
  }
}
.blog-top {
  h2 {font-size: 2.25rem;line-height: 2.75rem;margin-bottom: 1.5rem;}
}
.blog-item { margin-bottom: 2rem;
  a {text-decoration: none;}
  img {margin-bottom: .75rem;width: 100%;}
  h3 {margin-bottom: .5rem;}
  p {font-size: .875rem;line-height: 1.125rem;}
  .date {font-size: .875rem;line-height: 1.125rem;background: $primary-yellow;padding: .25rem;margin-bottom: .75rem;display: inline-block;float: right;}
  .labels-tags {
    a.tag-item {margin: .125rem .5rem .5rem 0;}
    a.label-item {margin: 0 .5rem .5rem 0;
      &.selected {margin-bottom: .375rem;}
    }
  }
}
.tags { margin: 1rem 0 2rem;display: inline-block;width: 100%;}
a.tag-item {background: $lighter-pink;color: $primary-pink;padding: .125rem .375rem;margin: 0 .75rem .75rem 0;text-decoration: none;font-size: 1rem;line-height: 1.25rem;
  border-width: 1px 2px 2px 1px;border-style: solid;border-color: $lighter-pink;border-radius: 2px;float: left;
  &.selected {border-color: #000000;background: $primary-pink;color: #fff;}
}
.labels { margin: 1rem 0 1.5rem;display: inline-block;width: 100%;}
a.label-item {padding: .25rem .5rem;margin-right: .5rem;text-decoration: none;font-size: 1rem;line-height: 1.25rem;text-transform: uppercase;font-weight: 700;float: left;border-radius: 2px;
  &.selected {border-width: 1px 2px 2px 1px;border-style: solid;border-color: #000000;}
  &.expert {background: $green;color: $blue;}
  &.insider {background: $blue;color: $light-pink;}
  &.buzz {background: $light-pink;color: $primary-yellow;}
}

.kepes-szoveges { margin-bottom: 4rem;
  .left-side {text-align: center;}
}

.gang {margin-bottom: 1rem;
  .gang-item {margin-bottom: 3rem;}
  .item {width: 100%;border-width: 1px 2px 2px 1px;border-style: solid;border-color: #000000;position: relative;
    .creative {display: none;}
    .bottom {position: absolute;bottom: 0;width: 100%;padding: .75rem;display: none;
      .name {font-size: 1rem;font-weight: 900;margin: 0;}
      .title {font-size: 1rem;font-weight: 400;margin: 0;}
    }
    &.notouch:hover, &.active {
      .portrait {display: none;}
      .creative {display: block;}
      .bottom {display: block;}
    }
    &.text { padding: 1.875rem;height: 100%;border: 0;position: relative;min-height: 250px;
      h3 {margin-bottom: .625rem;font-weight: 900;}
      a:not(.privacy-link) {font-size: 1.5rem;line-height: 1.825rem;font-weight: 600;color: $primary-yellow;text-decoration: none;white-space: nowrap;display: inline-block;
        margin-top: 1rem;position: absolute;bottom: 1.875rem;left: 1.875rem;}
      img {max-width: 100%;}
    }
    &.subsciption {padding: 1.5rem 1.25rem;
      h3 {margin-bottom: .625rem;font-weight: 900;}
      .gang-feliratkozas-form {margin-top: 1rem;}
      .btn-primary {color: $primary-yellow;padding: 0;margin-top: .5rem;}
      .success_subscribe {color: $primary-pink;margin-top: 1rem;font-size: 1.25rem;}
    }
  }
}

.page404 { background: $primary-yellow;padding-top: 4rem;padding-bottom: 4rem;
  h1 {font-size: 6rem;}
  img {max-width: 500px;height: auto;width: 100%;}
}

.subscribe { background: $light-pink;padding: 3.5rem 0;margin-bottom: 4rem;
  .subscribe-left {padding: 0 3rem;
    h3 {font-size: 4rem;line-height: 4.5rem;}
  }
  .btn-primary {color: $primary-yellow;margin-top: .5rem;}
  .success_subscribe {color: $primary-pink;margin-top: 1rem;font-size: 1.25rem;}
}
.subscribe-name {max-width: 21.5rem;
  .col:first-of-type {padding-right: .3125rem;}
  .col:last-of-type {padding-left: .3125rem;}
}

.allas { margin-bottom: 2rem;
  .allas-inner {padding: 2rem 2.5rem;
    .left-side {
      h2 {font-weight: 700;}
      .lead {font-size: 1.125rem;font-weight: 400;margin-bottom: 0;}
      .text {display: none;}
    }
    .right-side {position: relative;
      a {font-size: 1.5rem;font-weight: 600;line-height: 1.875rem;text-decoration: none;}
    }
    .more {position: absolute;bottom: 0;right: 0;color: $primary-pink;}
    .less {position: absolute;top: 0;right: 0;color: $primary-pink;display: none;}
    &.bg-green {
      .more {color: $blue;}
    }
    &.bg-light-pink {
      .more {color: $primary-yellow;}
    }
  }
}

.tiktok-blokk {margin-bottom: 4rem;
  .tiktok-item {width: 100%;padding: 80px 0;
    .tiktok-slick-inner {display: flex;}
    .text-container {display: inline-block;width: calc(100% - 672px);}
    .logo {width: 130px;display: inline-block;margin-bottom: 2rem;
      img {width: 100%;}
    }
    p {font-size: 1.125rem;margin-bottom: 2rem;}
    .bottom-infos {display: flex;
      .info-item { width: 33%;text-align: center;display: flex;flex-direction: column;margin-top: 1rem;padding-right: .5rem;
        &:last-of-type {padding-right: 0;}
        .data {font-size: 2.8125rem;font-weight: 500;margin-bottom: .5rem;}
        .info {}
      }
    }
    .image-container {background: url("images/mobile_frame.svg") top center/100% no-repeat;width: 306px;height: 504px;display: inline-block;padding: 21px 28px 2px 2px;margin-left: 30px;position: relative;
      img {width: 100%;height: 100%;object-fit: cover;border-radius: 24px;object-position: left center;}
      video {width: 100%;height: 100%;object-fit: cover;border-radius: 24px;object-position: left center;}
      .video-control {display: inline-block;position: absolute;width: 5rem;height: 5rem;top: 50%;left: 47%;transform: translate(-50%, -50%);cursor: pointer;transition: 1s;opacity: .4;
        &.play {background: url("images/icon-play.svg") center center/100% no-repeat;}
        &.pause {background: url("images/icon-pause.svg") center center/100% no-repeat;display: none;}
        &:hover {opacity: 1;}
      }
      &.playing {
        .video-control {opacity: 0;
          &.play {display: none;}
          &.pause {display: inline-block;}
        }
        &:hover {
          .video-control {opacity: .4;
            &:hover {opacity: 1;}
          }
        }
      }
    }
  }
  .slick-arrow { position: absolute; top: 320px;width: 26px;height: 30px;z-index: 10;cursor: pointer;
    &.slick-next {background: url("images/next.svg") center center no-repeat;right: 30px;
      &.show-important {display: inline-block !important;}
    }
    &.slick-prev {background: url("images/prev.svg") center center no-repeat;left: 30px;
      &.hide-important {display: none !important;}
    }
    &.slick-disabled {display: none !important;
      &.show-important {display: none !important;}
    }
  }
}

.loop-anim { display: flex;background: #000;color: #fff;overflow: hidden;padding: .875rem 0;font-size: 1rem;text-decoration: none;
  .scroll-text {display: inline-block;text-transform: uppercase;white-space: nowrap;
    transform: translateX(100vw);
    animation: my-animation 80s linear infinite;
    .font-heavy {font-weight: 900;}
  }
  &:hover { color: #fff;
    .scroll-text {
      animation-play-state: paused;
    }
  }
}

footer { background: $primary-yellow;padding: 2.5rem 0;position: relative;
  .szechenyi { background: url("images/szechenyi.png") top right/100% no-repeat;width: 400px;height: 300px;display: block;position: absolute;top: 0;right: 0;}
  .szechenyi-bottom {background: url("images/szechenyi_bottom.png") bottom right/100% no-repeat;width: 400px;height: 270px;display: none;position: absolute;bottom: 0;right: 0;}
  a {text-decoration: none;
    &:hover {text-decoration: underline;}
  }
  .socials {margin-top: 1.5rem;
    a {display: inline-block;height: 24px;width: 24px;margin: 0 1rem 0 0;
      &.behance {background: url("images/socials/b_icon_be.svg") top center/100% no-repeat;width: 39px;}
      &.insta {background: url("images/socials/b_icon_insta.svg") top center/100% no-repeat;}
      &.fb {background: url("images/socials/b_icon_fb.svg") top center/100% no-repeat;}
      &.tiktok {background: url("images/socials/b_icon_tiktok.svg") top center/100% no-repeat;}
      &.linkedin {background: url("images/socials/b_icon_linkedin.svg") top center/100% no-repeat;}
      &.vimeo {background: url("images/socials/b_icon_vimeo.svg") top center/100% no-repeat;}
    }
  }
  p {font-size: 1.25rem;line-height: 2.25rem;
    &.title {font-size: 2.8125rem;font-weight: 500;margin-bottom: 2rem;line-height: 3.375rem;min-height: 6.75rem;}
  }
  .copyright {font-size: 1.125rem;line-height: 1.5rem;margin-right: 3rem;}
  .aszf {font-size: .875rem;}
  .footer-bottom {margin-top: 1rem;}
}

.ccm-page {
  form { font-size: .875rem;
    .form-group label {display: none}
    .form-control, input[type="text"], textarea { border: 1px solid $basefont-color;background: transparent;border-radius: 0;margin-bottom: .625rem;font-size: .75rem;max-width: 20rem;
      &:active, &:focus {border: 1px solid $basefont-color;background: transparent;}
    }
    textarea.form-control {min-height: 5rem;}
    .btn-primary {
      &:active, &:focus, &:disabled {}
    }
    .form-check {line-height: 1.25rem;padding-left: 1.325rem;margin-bottom: .625rem;min-height: 1.25rem;
      .form-check-input { background-color: transparent;border-color: $basefont-color;
        &[type="checkbox"] {border-radius: 0;width: 1.25rem;height: 1.25rem;margin-top: 0;}
        &:checked {background-color: #000;border-color: #000;}
      }
      &.error-check {
        .form-check-input {border-color: $red;}
      }
    }
    .form-check-label {font-size: .75rem;margin-left: 0.5rem;}
    .success-message {margin-top: .5rem;display:none;color: $green;}
  }

  .cookie-alert-kont {position: fixed; left: 0; bottom: 0; width: 100%;background: $green;z-index: 10;padding: 2rem 0;
    .container-lg {display: flex;}
    .cookie-icon {float:left;width: 64px;height: 64px;display: inline-block;background: url(images/hello_cookie.svg) top center/100% no-repeat;margin-right: 2rem;}
    .text {float: left;font-size: 1.125rem;
      a { text-decoration: none;
        &:hover {text-decoration: underline;}
      }
    }
    button.btn-primary {margin-top: .75rem;
    }
  }
}

#szechenyiModal {
  .modal-dialog {width: 900px;max-width: 97%;}
  .modal-content {border-radius: 0;}
  .modal-body {padding: 0;
    img {max-height:88vh;}
  }
}

@import "media_queries";
