@use 'sass:math';

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return math.div($number,$number * 0 + 1);
  }

  @return $number;
}

@keyframes spinner {
  0% {transform: translate3d(-50%, -50%, 0) rotate(0deg);}
  100% {transform: translate3d(-50%, -50%, 0) rotate(360deg);}
}

@keyframes my-animation {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-100%);
  }
}

@mixin gradient($direction, $list) {
  background: nth(nth($list, 1), 1);
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Black.eot');
  src: url('fonts/OpenSauceSans-Black.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSauceSans-Black.woff2') format('woff2'),
  url('fonts/OpenSauceSans-Black.woff') format('woff'),
  url('fonts/OpenSauceSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-ExtraBold.eot');
  src: url('fonts/OpenSauceSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSauceSans-ExtraBold.woff2') format('woff2'),
  url('fonts/OpenSauceSans-ExtraBold.woff') format('woff'),
  url('fonts/OpenSauceSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Bold.eot');
  src: url('fonts/OpenSauceSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSauceSans-Bold.woff2') format('woff2'),
  url('fonts/OpenSauceSans-Bold.woff') format('woff'),
  url('fonts/OpenSauceSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-SemiBold.eot');
  src: url('fonts/OpenSauceSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSauceSans-SemiBold.woff2') format('woff2'),
  url('fonts/OpenSauceSans-SemiBold.woff') format('woff'),
  url('fonts/OpenSauceSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Medium.eot');
  src: url('fonts/OpenSauceSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSauceSans-Medium.woff2') format('woff2'),
  url('fonts/OpenSauceSans-Medium.woff') format('woff'),
  url('fonts/OpenSauceSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Regular.eot');
  src: url('fonts/OpenSauceSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSauceSans-Regular.woff2') format('woff2'),
  url('fonts/OpenSauceSans-Regular.woff') format('woff'),
  url('fonts/OpenSauceSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('fonts/OpenSauceSans-Light.eot');
  src: url('fonts/OpenSauceSans-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/OpenSauceSans-Light.woff2') format('woff2'),
  url('fonts/OpenSauceSans-Light.woff') format('woff'),
  url('fonts/OpenSauceSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

$basefont1: 'Open Sauce Sans';
$basefont2: 'Helvetica';
$basefont3: 'sans-serif';

$secondary-font: '';

$basefont-size: 16px;
$base-lineheight: normal;

$basefont-color: #000;
$secondary-font-color: #171A20;

$primary-yellow: #FFFA8E;
$primary-pink: #FF33CC;
$light-pink: #FF99E5;
$lighter-pink: #FFE0F7;
$red: #FF321D;
$blue: #025CC6;
$green: #4CD892;
