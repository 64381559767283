@media only screen and (min-aspect-ratio: 8/5) and (max-height:650px) {

}

@media only screen and (min-aspect-ratio: 9/5) and (min-height:1000px) {

}

@media (min-width: 576px) {
  
}

@media (min-width: 768px) {
  
}

@media (min-width: 992px) {
  
}

@media (min-width: 1200px) {
  
}

@media only screen and (max-width:1399px) {
  .tiktok-blokk {
    .tiktok-item {
      .text-container {width: calc(100% - 560px);
        .bottom-infos {
          .info-item {
            .data {font-size: 2.5rem;}
            .info {font-size: 1rem;}
          }
        }
      }
      .image-container {width: 260px;height: 429px;padding: 18px 24px 3px 2px;margin-left: 25px;
        img, video {border-radius: 21px;}
      }
    }
    .slick-arrow {top: 285px;}
  }
  .loop-anim {
    .scroll-text {animation: my-animation 70s linear infinite;}
  }
}

@media only screen and (max-width:1199px) {
  .allas .allas-inner {
    .right-side a {font-size: 1.125rem;}
  }
  footer {
    .szechenyi {display: none;}
    .szechenyi-bottom {display: block;}
    .footer-bottom {max-width: calc(100% - 320px);}
  }
}

@media only screen and (max-width: 1100px) {
  .tiktok-blokk {
    .slick-arrow {
      &.slick-next {right: 10px;}
      &.slick-prev {left: 10px;}
    }
  }
}

@media only screen and (max-width:991px) {
  .container-lg {padding-right: 1.5rem;padding-left: 1.5rem;}
  .works .masonry {
    .item.text { padding: 1.5rem;
      h2 {font-size: 2.25rem;}
      > p {font-size: .875rem;line-height: 1.125rem;}
    }
    .item.subsciption .btn-primary {font-size: 1.25rem;}
  }
  .tiktok-blokk {
    .tiktok-item {
      .container-lg {padding-left: 3rem;padding-right: 3rem;}
      .text-container {width: calc(100% - 510px);
        .logo {width: 110px;}
        p {font-size: 1rem;}
        .bottom-infos {
          .info-item {
            .data {font-size: 2.25rem;}
            .info {}
          }
        }
      }
      .image-container {width: 230px;height: 380px;padding: 16px 22px 3px 2px;margin-left: 25px;
        img, video {border-radius: 18px;}
      }
    }
    .slick-arrow {top: 285px;}
  }
}

@media only screen and (max-width:910px) {

}

@media only screen and (max-width:700px) {

}

@media only screen and (max-width:767px) {
  .container-md {padding-left: 1.5rem;padding-right: 1.5rem;}
  nav.navbar .dropdown-nav, nav.navbar .dropdown-nav-socials {display: inline-block;}
  nav.navbar .navbar-collapse { position: absolute;top: 0;right: 0;width: 100%;max-width: 25rem;padding: 1.5rem;z-index: 1000;background: $light-pink;height: 100vh;transition: none;
    ul.navbar-nav { padding: 2.5rem 0 0;
      .nav-item { text-align: left; padding-bottom: 1.5rem;
        &:last-of-type {padding: 0}
        a.nav-link {font-size: 2rem; line-height: 2.5rem; margin: 0;display: inline-block;border-bottom: 2px solid transparent;color: #000;font-weight: 600;
          &:hover, &:active {text-decoration:none;}
        }
      }
    }
    .langs {float: right;margin-right: 4rem;margin-top: .25rem;
      span:last-of-type {display: none}
      a {font-size: 1rem;font-weight: 600;text-transform: uppercase;text-decoration: none;}
    }
    .navbar-text {display: none;}
    .m-logo {float:left;width: 5rem;height: 2rem;background: url("/application/themes/hello/images/hello_agency_logo_black.svg") top center/100% no-repeat;margin-top: .125rem;}
  }
  .main-top .szechenyi-fixed {width: 300px;}
  .kepes-szoveges {
    .left-side {text-align: left;margin-bottom: 3rem;}
  }
  .rumour-block {
    .rumour-right {margin-top: 2rem;}
  }
  footer {
    p.title {min-height: auto;}
    .footer-form {margin-top: 3rem;}
    .footer-bottom {margin-top: 3rem;max-width: calc(100% - 250px);}
    .szechenyi-bottom {display: block;width: 300px;}
  }
  .subscribe {
    .subscribe-right {padding-left: 3rem;padding-top: 2rem;}
  }
  .allas {
    .right-side {height: 4rem;}
  }
  .tiktok-blokk {
    .tiktok-item {padding: 64px 0;
      .tiktok-slick-inner {}
      .text-container {width: calc(100% - 672px);
        .logo {width: 110px;}
        p {font-size: 1rem;}
        .bottom-infos {
          .info-item {
            .data {font-size: 2.25rem;}
            .info {}
          }
        }
      }
      .image-container {width: 306px;height: 504px;padding: 21px 28px 2px 2px;margin-left: 25px;
        img, video {border-radius: 24px;}
      }
    }
    .slick-arrow {top: 310px;
      &.slick-next {display: none !important;}
      &.slick-inner-next {background: url("images/next.svg") center center no-repeat;right: -21px;top: 246px;z-index: 20;}
      &.slick-inner-prev {background: url("images/prev.svg") center center no-repeat;left: -21px;top: 246px;z-index: 20;}
    }
  }
  .loop-anim {
    .scroll-text {animation: my-animation 60s linear infinite;}
  }
}

@media only screen and (max-width:576px) {
  h1 {font-size: 3.25rem;line-height: 4rem;}
  .text-30 {font-size: 1.625rem !important;line-height: 2.125rem;}
  .text-45 {font-size: 2.25rem !important;line-height: 2.75rem;}
  .tartalmi-block {
    .order-last {margin-top: 2rem;}
    img.img-fluid {width: 100%}
  }
  .main-top {
    p {font-size: 3rem;line-height: 4rem;}
  }
  nav.navbar .navbar-collapse {max-width: 100%;}
  .szolg-content-block, .content-block, .blog-content-block {
    h1 {overflow-wrap: break-word;}
  }
  .blog {
    .blog-bottom {display: block;
      .tags {margin-bottom: 0;}
      .back {float: right;margin-bottom: 4rem;}
    }
  }
  footer {
    .footer-bottom {margin-bottom: 180px;max-width: 100%;}
  }
  .tiktok-blokk {
    .tiktok-item {
      .text-container {
        .bottom-infos {flex-direction: column;align-items: center;
          .info-item {margin-bottom: .5rem;}
        }
      }
    }
  }
}

@media only screen and (max-width:576px) and (min-height: 600px) {
}

@media only screen and (max-width:450px) {
}

@media only screen and (max-width:400px) {
  .subscribe-name {max-width: none;}
  .ccm-page {
    form {
      .form-control, input[type="text"], textarea { max-width: 100%;}
    }
  }
  .subscribe {padding: 2rem 0;
    .subscribe-left {padding: 0 2.25rem;
      h3 {font-size: 2.25rem;line-height: 3rem;font-weight: 700;}
    }
    .subscribe-right {padding-left: .75rem;}
    .feliratkozas-form {padding: 0 1.5rem;}
  }
  footer {
    p {font-size: 1rem;line-height: 2rem;
      &.title {font-size: 2rem;line-height: 3rem;}
    }
  }
}

@media only screen and (max-width:376px) {

}
